import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import earthTexture from '../img/earthmap1k.jpg'; // Path to your Earth texture image

const ThreeJSComponent = () => {
  const mountRef = useRef(null);
  const markers = useRef([]); // Ref for multiple marker objects
  const [clickedMarker, setClickedMarker] = useState(null); // State for clicked marker details

  useEffect(() => {
    let renderer, scene, camera, controls, raycaster, mouse;
    const markerDetails = {}; // Store marker details for quick access

    // Scene setup
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    
    renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Load Earth texture
    const textureLoader = new THREE.TextureLoader();
    const earthTextureMap = textureLoader.load(earthTexture, () => {
      console.log('Texture loaded successfully.');
    }, undefined, (error) => {
      console.error('Error loading texture:', error);
    });

    // Globe setup with texture
    const geometry = new THREE.SphereGeometry(3, 32, 32); // Increase the radius to 3 for a bigger globe
    const material = new THREE.MeshStandardMaterial({ map: earthTextureMap });
    const light = new THREE.DirectionalLight(0xffffff, 3);
    light.position.set(5, 3, 5);
    scene.add(light);
    const globe = new THREE.Mesh(geometry, material);
    scene.add(globe);

    // Create markers for specific latitude and longitude coordinates
    // const locations = [
    //   { name: 'Washington D.C.', lat: 38.8951, lon: -77.0364 },
    //   { name: 'Borneo', lat: 1.1105, lon: 65.9758 },
      // Add more locations as needed
    // ];

    // locations.forEach(location => {
    //   const markerGeometry = new THREE.SphereGeometry(0.05, 16, 16); // Adjust size and segments as needed
    //   const markerMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
    //   const marker = new THREE.Mesh(markerGeometry, markerMaterial);
    //   const markerPosition = calculateMarkerPosition(location.lat, location.lon);
    //   marker.position.copy(markerPosition);
    //   globe.add(marker);
    //   markers.current.push(marker); // Store marker reference
    //   markerDetails[marker.id] = location; // Store marker details
    // });

    // Function to calculate marker position based on latitude and longitude
    function calculateMarkerPosition(latitude, longitude) {
      const phi = THREE.MathUtils.degToRad(90 - latitude);
      const theta = THREE.MathUtils.degToRad(longitude + 180);
      const radius = 3; // Radius of the globe
      return new THREE.Vector3(
        radius * Math.sin(phi) * Math.cos(theta),
        radius * Math.cos(phi),
        radius * Math.sin(phi) * Math.sin(theta)
      );
    }

    camera.position.z = 7;

    // Add OrbitControls
    controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;

    // Raycaster for detecting click
    raycaster = new THREE.Raycaster();
    mouse = new THREE.Vector2();

    // Animation function
    const animate = () => {
      requestAnimationFrame(animate);

      // Rotate the globe automatically
      globe.rotation.x += 0.0010;
      globe.rotation.y += 0.0010;

      // Update controls for user interaction
      controls.update();

      // Render the scene
      renderer.render(scene, camera);
    };

    animate();

    // Handle window resizing
    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    // Handle mouse click
    const handleClick = (event) => {
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(markers.current);

      if (intersects.length > 0) {
        const intersectedMarker = intersects[0].object;
        setClickedMarker(markerDetails[intersectedMarker.id]);
      } else {
        setClickedMarker(null);
      }
    };

    window.addEventListener('click', handleClick);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('click', handleClick);
      if (mountRef.current && mountRef.current.removeChild) {
        mountRef.current.removeChild(renderer.domElement);
      }
      // Clean up controls if necessary
      if (controls) {
        controls.dispose();
      }
      // Clean up scene and objects
      scene.remove(globe);
      markers.current.forEach(marker => globe.remove(marker));
      markers.current.length = 0; // Clear markers array
      // Dispose objects to free up memory
      geometry.dispose();
      material.dispose();
      earthTextureMap.dispose();
      renderer.dispose();
    };
  }, []);

  return (
    <div ref={mountRef}>
      {clickedMarker && (
        <div style={{ position: 'absolute', top: '115px', left: '10px', background: 'white', color: 'black',  padding: '10px', borderRadius: '5px' }}>
          <strong>{clickedMarker.name}</strong>
          <p>Latitude: {clickedMarker.lat}</p>
          <p>Longitude: {clickedMarker.lon}</p>
        </div>
      )}
    </div>
  );
};

export default ThreeJSComponent;
