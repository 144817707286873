import React, { useEffect, useState } from 'react';
import ThreeJSComponent from './component/ThreeJs/ThreeJSComponent';
import "./App.css";
// import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
// import Navbar from './component/NavBar/NavBar';
// import Footer from './component/Footer/Footer';
// import Home from './component/Home/Home';
// import About from './component/NavBar/Pages/About/About';
// import Contact from './component/Home/Contact/Contact';
// import Service from './component/NavBar/Pages/Services';
// import GisPage from './component/NavBar/Pages/Service/Gis/Gis';
// import GisSpatialPage from './component/NavBar/Pages/Service/GisSpatial/GisSpatial';
// import GisSciencePage from './component/NavBar/Pages/Service/GisScience/GisScience';
// import GisICTPage from './component/NavBar/Pages/Service/GisICT/Gis-ICT';
// import OrganisationalStructure from './component/NavBar/Pages/About/OrganisationalStructure';
// import YearlyMilestone from './component/NavBar/Pages/About/YearlyMilestone';

// const ScrollToTop = () => {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return null;
// }

const App = () => {
  return (
    <div>
      <ThreeJSComponent />
    </div>
    // <Router>
    //   <ScrollToTop />
    //   <div className="App">
    //     <Navbar />
    //     <Routes>
    //       <Route path="/" element={<Home />} />
    //       <Route path="/Services" element={<Service />} />
    //       <Route path="/About" element={<About />} />
    //       <Route path="/OrganisationalStructure" element={<OrganisationalStructure />} />
    //       <Route path="/YearlyMilestone" element={<YearlyMilestone />} />
    //       <Route path="/Contact" element={<Contact />} />
    //       <Route path="/Gis" element={<GisPage />} />
    //       <Route path="/GisSpatialPage" element={<GisSpatialPage />} />
    //       <Route path="/GisSciencePage" element={<GisSciencePage />} />
    //       <Route path="/GisICTPage" element={<GisICTPage />} />
          
    //     </Routes>
    //     <Footer />
    //   </div>
    // </Router>
  );
};

export default App;
