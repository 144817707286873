import React, { useEffect, useState } from 'react';
import Globe from './Globe';  
import Logo from '../img/360Geoinfo_Logo_PNG.png';
import './ThreeJSComponent.css';

const words = ["Spatial", "ICT", "Science", "Information System"];

const ThreeJSComponent = () => {
  const [currentWord, setCurrentWord] = useState(words[0]);
  const [fade, setFade] = useState(false);
  let wordIndex = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        wordIndex = (wordIndex + 1) % words.length;
        setCurrentWord(words[wordIndex]);
        setFade(false);
      }, 1000); // Duration should match the transition time in CSS
    }, 3000); // Total duration including fade out, change and fade in

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='background'>
      <img className="logo" src={Logo} alt="Logo" />
      <div className="title-h2">
        <h2>ENHANCING YOUR GEO</h2>
        <div className="title-animation-container">
          <h2 className={`title-animation ${fade ? 'fade-out' : ''}`}>{currentWord}</h2>
        </div>
        <h2>EXPERIENCE</h2>
      </div>
      <h1 className="title-h1">STAY TUNED</h1>
      <div className="globe-container">
        <Globe />
      </div>
    </div>
  );
};

export default ThreeJSComponent;
